
// 默认 配置 没有记录的医院当使用默认配置
module.exports = {
	hospitalName: "default",
	themeTy: 1, // 默认蓝色主题1   会让全部蓝色匹配图片
	homePage: "",// 默认页面
	isServiceDisabledBool: true,//是否有残联模块
	
	// 不同医院在不同模块的页面配置
	// 家医
	FamilyDoctorPage: {
		checkToPage: '/pages/familyDoctorNot/index'
	},









	// 门诊缴费
	OutpatientPayment: {
		paymentWayArr: [
			// #ifndef MP-ALIPAY
			{
				way: 'WEIXIN',
				provider: 'wxpay',
				name: '微信支付',
				img: '/static/home/WxZf.png',
			},
			// #endif
			// #ifdef APP-PLUS || MP-ALIPAY
			{
				way: 'ALIPAY', // ALIPAY 那边处理会是支付宝支付
				name: '支付宝支付',
				provider: 'alipay',
				img: '/static/home/zfb.png'
			},
			// #endif
		],
		isAllSelect: false
	}
	
}
