module.exports = {
	hospitalName:"jkyz",
	isServiceDisabledBool: true,//是否有残联模块
	// 家医
	FamilyDoctorPage: {
		checkToPage: '/pages/familyDoctorNot/index'
	},
	
	
	
	
	
	
	
	paymentWayArr: [{
		way: 'ALIPAY', // ALIPAY 那边处理会是支付宝支付
		name: '支付宝支付',
		provider: 'alipay',
		img: '/static/home/zfb.png'
	}],
	// 门诊缴费
	OutpatientPayment: {
		paymentWayArr: [
		// #ifdef MP-WEIXIN
		{
			way: 'WEIXIN',
			provider: 'wxpay',
			name: '微信支付',
			img: '/static/home/WxZf.png',
		},
		// #endif
		// #ifdef APP-PLUS
		{
			way: 'ALIPAY', // ALIPAY 那边处理会是支付宝支付
			name: '支付宝支付',
			provider: 'alipay',
			img: '/static/home/zfb.png'
		},
		// #endif
		]
	},
}
